'use strict';

$(document).ready(function(){


  function initGrid(){

    var $grid = $('.grid').imagesLoaded( function() {
      // init Masonry after all images have loaded
      var grid = document.querySelector('.grid');
      var msnry = new Masonry( grid, {
        itemSelector: '.news',
        transitionDuration: '0.2s'
      });

    });
  }

  function initNewsPager(){
    $('.moreextnews').click(function(e){
      e.preventDefault();
      var nextPage = $(this).data('url') + 1;
      var url = '/ajax/externalnews.html/p';
      var lang = $(this).data('lang');
      if(lang == 'fr' || lang == 'en'){
        url = '/' + lang + url;
      }
      $.get( url + nextPage, function( data ) {
        $( ".newsholder" ).html( data ).fadeIn("slow");
        initNewsPager();
        initGrid();
        scrollUp();
      });
    });

    $('.moreintnews').click(function(e){
      e.preventDefault();
      var nextPage = $(this).data('url') + 1;
      var url = '/ajax/internalnews.html/p';
      var lang = $(this).data('lang');
      if(lang == 'fr' || lang == 'en'){
        url = '/' + lang + url;
      }
      $.get( url + nextPage, function( data ) {
        $( ".intnewsholder" ).html( data ).fadeIn("slow");
        initNewsPager();
        scrollUp();
        //initGrid();
      });
    });
  }

  function scrollUp(){
    var body = $("html, body");
    body.stop().animate({scrollTop:0}, '500', 'swing');
  }



  if($('body').hasClass('front') === true || $('body').hasClass('news-page') === true || $('body').hasClass('newsitem-page') === true || $('body').hasClass('theme-page') === true){
    initGrid();
  }

  /*if($('body').hasClass('front') === true || $('body').hasClass('about') === true) {
    $('.flexslider').flexslider({
      animation: "slide",
      directionNav: false,
      minItems: 1,
      maxItems: 4
    });

  }*/


  $('.rabnewsbtn').click(function(){
    if($(this).hasClass('inactive') === true) {
        $(this).removeClass('inactive');
        //$('.external').show();
    }else{
        $(this).addClass('inactive');
        //$('.external').hide();
    }

    // re-init
    initGrid();

  });


  $('.rabdocsbtn').click(function(){
    if($(this).hasClass('inactive') === true) {
        $(this).removeClass('inactive');
        $('.external').show();
    }else{
        $(this).addClass('inactive');
        $('.external').hide();
    }

  });

  $('.search-btn').click(function(e){
    if($(this).parent().hasClass('search-open') === true) {
      $(this).parent().removeClass('search-open');
    }else{
      e.preventDefault();
      $(this).parent().addClass('search-open');
    }

  });


  $('.hamburger').click(function(){
    if($('body').hasClass('mobile-menu-open') === true){
      $('body').removeClass('mobile-menu-open');
    }else{
      $('body').addClass('mobile-menu-open');
      scrollUp();
    }

  });



  initNewsPager();

  $('.member-link').modaal({'background':'#ffffff','overlay_opacity':'0.9'});

  /*$('.ext-btn').click(function(){
    $('.int').hide();
    $('.ext').show();
    $('.ext-btn').removeClass('inactive');
    $('.int-btn').addClass('inactive');
    $grid.masonry();
  });*/

  // if($('body').hasClass('front') === true){
  //   var sticky = new Waypoint.Sticky({
  //     element: $('.stickme')[0]
  //   })
  // }

});
